$border-color: #D1D5DB;

.article-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 6px !important;
}

.article-content {
  font-size: large !important;
  line-height: 1.6 !important;
  max-width: 800px !important;
 }

.article-content p {
  margin-bottom: 1.5em;
}

.article-content h1,
.article-content h2,
.article-content h3 {
  margin-top: 1.5em !important;
  margin-bottom: 0.8em !important;
}

.article-content a {
  color: #0066cc;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-content a:hover {
  color: #003366;
  text-decoration: underline;
}

.article-content ul,
.article-content ol {
  margin-bottom: 1.5em;
  padding-left: 25px;
}

.article-content li {
  margin-bottom: 0.5em;
}

@media (max-width: 600px) {
  .article-content {
    font-size: medium !important;
  }
}

.card {
  border-radius: 15px !important;
}

.intro {
  font-size: larger; /* Slightly larger than the body text */
  line-height: 1.5; /* Comfortable spacing */
  color: #444; /* Slightly lighter than body text for subtle emphasis */
  text-align: justify; /* Align text for neat block appearance */
}

a:hover{
  .card-title {
    text-decoration: underline !important;
  }
}

.score {
  font-size: 48px;
  font-weight: bold;
  color: white;
  background-color: #c60000;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carousel img {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
    object-fit: cover;
    height: 400px;
}

.carousel-caption h3 {
    font-size: 28px;
    font-weight: bold;
    color: #ffcc00;
}

.carousel-caption p {
    font-size: 16px;
}

.score-card {
  border: 1px solid $border-color !important;
  border-radius: 10px;
  padding: 30px;
}

* {
  font-family: "Tajawal", serif;
  text-align: right !important;
  direction: rtl !important;
   -webkit-font-smoothing: antialiased !important; /* Smooths text in WebKit browsers */
  -moz-osx-font-smoothing: grayscale !important; /* Smooths text in Firefox on macOS */
}

.footer {
  font-size: small;
}

.footer-message {
  text-align: center !important;
  direction: ltr !important;
}

.category{
  color: #c60000 !important;
  text-decoration: none !important;
  font-weight: bold;
}

.post-title {
  font-size: large !important;
  text-decoration: none !important;
  font-weight: bold;
}

.note-image {
  margin-bottom: 20px;
  border-radius: 6px;
  aspect-ratio: 16/9;
  width: 100%;
}

@media (min-width: 768px) {
  .note-image {
    width: 250px;
  }
}

.category * { 
  color: #c60000 !important; 
  text-decoration: none !important;
}

.date {
  color: grey !important;
  font-size: small !important;
  font-weight: bold !important;
}

  .navbar-nav .nav-link {
    font-size: 1rem; // Adjust font size for better readability
    margin-right: 1rem; // Add spacing between nav links
    text-decoration: none !important;
    font-weight: bold;

    &:hover {
        border-bottom: 3px solid !important;
    }

     &.active {
        border-bottom: 3px solid !important;
    }
  }

.carousel-title {
  color: white !important;
  text-decoration: none !important;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  padding: 10px; /* Add some padding for better spacing */
  border-radius: 8px; /* Optional: Rounded corners */
  color: white; /* Ensure text is readable */
  text-align: center; /* Center-align text */
}

.navbar-toggler {
  border: none !important;
  font-size: 1.25rem;
  &:hover {
    border: none !important;
  }
}

:root {
  color-scheme: light dark;
}

iframe {
  color-scheme: light;
}

/* Customize NProgress */
#nprogress .bar {
  background: rgb(249, 230, 158) !important; /* Change color */
  height: 5px !important;
}

/* Ensure the carousel arrows are visible and easily distinguishable */
.carousel-control-prev, .carousel-control-next {
    filter: invert(1); /* Inverts the arrow color for better contrast */
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent dark background to the arrow */
    border-radius: 50%; /* Makes the arrow container circular */
    width: 40px; /* Adjust the width of the arrows */
    height: 40px; /* Adjust the height of the arrows */
}

.dropdown-toggle::after {
    display: none !important;
}