$color: #EDEDED; 
$border-color: #242629;
$background: #0A0B0D;
$hover-color: rgb(249, 230, 158);
$muted-color: #9CA3AF;

body.dark {
    background-color: $background !important;
    color: $color !important;

    .navbar-icon {
        color: $color !important;
    }

    .post-title {
        color: $color !important;
        &:hover {
            color: $hover-color !important;
        }
    }

    .navbar-toggler {
        color: white !important;
    }
    
    .post-title {
        color:$color;
    }

    .home-article-border-bottom {
        border-bottom: solid 1px $border-color !important;
    }

    .article-content * {
        color: $color !important;
    }
    
    .comment, comment-details {
        color: white !important;
    }
    
    .breadcrumb {
        background-color: $background !important;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;

        .breadcrumb-item {
            color: $color;
            font-weight: bold;

            &:hover {
                color: $hover-color;
            }

            &:active, &.active {
                color: $muted-color;
            }
        }

        .breadcrumb-item::before {
            color: $border-color;
        }
    }

    .card {
        border: 1px solid $border-color !important;
        background-color: $background !important;

        .card-title {
            color: $color !important;
        }
    }

    .navbar {
        border-bottom: 1px solid $border-color;
        background-color: $background !important;
        color: $color !important;
    }

    .text-muted {
        color: $muted-color !important;
    }

    
    .navbar-nav .nav-link {
        color: $color; // Default link color

        &:hover {
        color: $hover-color; // Highlight on hover
        }

        &.active {
        color: $hover-color !important; /* Color for active link */
        }
    }

    .card-title {
        color: $color !important;
        font-size: medium !important;
        font-weight: bold !important;
    }

    .footer {
        border-top: 1px solid $border-color;

        a {
            color: $muted-color !important;
        }

        background-color: $background !important;
        color: white !important;
    }

    // Table Styles
    .table {
        background-color: $background;
        color: $color;

        th, td {
            border: 1px solid $border-color;
        }

        th {
            background-color: darken($background, 5%);
            color: $color;
        }

        td {
            background-color: $background;
            color: $color;
        }
    }

    .table-hover tbody tr:hover {
        background-color: lighten($background, 5%);
        color: $hover-color;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: darken($background, 3%);
    }

    .carousel-title:hover {
        color: $hover-color !important;
    }

    .ql-container {
        background-color: #333;
        color: white;
        min-height: 400px;
        border : none !important;
        font-size: large !important;
    }

    .ql-toolbar {
        border: none !important;
    }

    .ql-editor {
        background-color: $background !important;
        color: white !important;
    }

    .ql-tooltip {
        background-color: $background !important;
        color: white !important;
    }

    .editor-light {
        background-color: white;
    }

    .editor-dark {
        background-color: $background !important;
        color: white !important;
    }

    .ql-editor.ql-blank::before {
        color: grey !important;
    }

    .ql-container {
        background-color: $background !important;
    }

    .ql-editor {
        color: #f0f0f0 !important;
    }

    .modal-content {
        background-color: #121212;
        color: #fff;
    }

    .modal-header {
        background-color: #1e1e1e;
        color: #fff;
    }

    .modal-body {
        background-color: #121212;
        color: #fff;
    }

    .modal-input {
        background-color: #1e1e1e;
        color: #fff;
        border: none;
    }

    .modal-body input::placeholder {
        color: #bbb;
    }

    .modal-header .btn-close {
        font-size: 0.8rem;
        filter: invert(1);
    }

    .modal-header .btn-close {
        filter: invert(1);
    }
}